import styles from './index.css';

const FooterCopyright = () => (
  <div className="footer-copyright">
    <small>
      &copy;&nbsp;
      {`${new Date().getFullYear()} HafH. All Rights Reserved.`}
    </small>
    <style jsx>{styles}</style>
  </div>
);

export default FooterCopyright;
