import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .footer-sns-icons {
    display: flex;
  }
  .footer-sns-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    border: 1px solid ${COLORS.black400};
    border-radius: 50%;
    :last-child {
      margin-right: 0;
    }
  }
`;

export default styles;
