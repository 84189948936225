import FooterBottomLinks from './FooterBottomLinks';
import FooterCopyright from './FooterCopyright';
import FooterLang from './FooterLang';
import FooterLogo from './FooterLogo';
import FooterMenu from './FooterMenu';
import FooterSNS from './FooterSNS';
import styles from './index.css';

const Footer = ({ showMenu = true, showSNS = true }) => (
  <footer className="footer">
    <div className="footer-wrapper">
      <div className="footer-top-block">
        <div className="footer-logo-wrapper">
          <FooterLogo />
          <FooterCopyright />
        </div>
        {showMenu && <FooterMenu />}
      </div>
      <div className="footer-divider" />
      <div className="footer-bottom-block">
        {showSNS && <FooterSNS />}
        <FooterLang />
      </div>
      <FooterBottomLinks />
    </div>
    <style jsx={true}>{styles}</style>
  </footer>
);

export default Footer;
