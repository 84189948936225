import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .footer-bottom-links-wrapper {
    margin-bottom: 24px;

    .footer-bottom-links {
      display: flex;
      flex-wrap: wrap;
    }
    .footer-bottom-link {
      font-size: 12px;
      line-height: 24px;
      :global(a) {
        color: ${COLORS.black900};
      }
      &::after {
        content: '|';
        margin: 0 12px;
        color: ${COLORS.black900};
      }
      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }
`;

export default styles;
