import IconInstagram from '@assets/sns-icons/footer/icon-instagram.svg';
import IconLine from '@assets/sns-icons/footer/icon-line.svg';
import IconX from '@assets/sns-icons/footer/icon-x.svg';
import useLocation from '@hooks/useLocation';
import styles from './css';

const itemsCountryMap = {
  ja: [
    {
      component: IconInstagram,
      href: 'https://www.instagram.com/hafhglobal/',
      title: 'Instagram',
    },
    {
      component: IconX,
      href: 'https://twitter.com/HafHtheWorld',
      title: 'Twitter',
    },
  ],
  ko: [
    {
      component: IconInstagram,
      href: 'https://www.instagram.com/hafhkorea/',
      title: 'Instagram',
    },
  ],
  tw: [
    {
      component: IconInstagram,
      href: 'https://supr.link/E6QoB',
      title: 'Instagram',
    },
    {
      component: IconLine,
      href: 'https://supr.link/xvTO8',
      title: 'Line',
    },
  ],
};

const FooterSNS = () => {
  const { isKorea, isTaiwan } = useLocation();

  const items = isKorea
    ? itemsCountryMap.ko
    : isTaiwan
    ? itemsCountryMap.tw
    : itemsCountryMap.ja;

  return (
    <div>
      <ul className="footer-sns-icons">
        {items.map(({ component: Component, href, title }) => (
          <li className="footer-sns-icon" key={title}>
            <a href={href} rel="noopener noreferrer" target="_blank">
              <span className="sr-only">{title}</span>
              <Component />
            </a>
          </li>
        ))}
      </ul>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default FooterSNS;
